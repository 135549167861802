import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-mathtype";

function MeuEditor({ initialValue, onChange }) {
  // Função para fazer o upload de uma imagem
  const handleUploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);

      // Aguarda a resolução da promise
      const response = await fetch(
        "https://api.colegionaval.com.br/public/api/upload-image",
        {
          method: "POST",
          "Content-Type": "multipart/form-data",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Falha no upload da imagem");
      }

      const data = await response.json();

      // A imagem foi enviada com sucesso
      return { default: data.url };
    } catch (error) {
      // Lida com erros no upload da imagem
      console.error("Erro no upload da imagem:", error);
      throw error;
    }
  };

  // Função para deletar uma imagem
  const handleDeleteImage = (imageSrc) => {
    // Construa a URL da API de exclusão de imagem
    const apiUrl = "https://api.colegionaval.com.br/public/api/delete-image";

    // Faça a solicitação DELETE
    fetch(apiUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ src: imageSrc }),
    })
      .then((response) => {
        if (response.ok) {
          // A imagem foi excluída com sucesso
          console.log("Imagem excluída com sucesso");
        } else {
          // A solicitação falhou, lide com o erro aqui
          console.error("Erro ao excluir a imagem:", response.status);
        }
      })
      .catch((error) => {
        // Ocorreu um erro durante a exclusão da imagem
        console.error("Erro ao excluir a imagem:", error);
      });
  };

  const API_URL = "https://api.colegionaval.com.br/public/api";
  const UPLOAD_ENDPOINT = "upload-image";
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            let headers = new Headers();
            headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                resolve({
                  default: res.url,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      data={initialValue}
      height={"300px"}
      editor={ClassicEditor}
      config={{
        extraPlugins: [uploadPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "fontsize",
            "alignment",
            "fontColor",
            "fontBackgroundColor",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "subscript",
            "superscript",
            "|",
            "link",
            "outdent",
            "indent",
            "bulletedList",
            "numberedList",
            "todoList",
            "code",
            "codeBlock",
            "insertTable",
            "blockQuote",
            "imageUpload",
            "ImageToolbar",
            "mediaEmbed",
            "undo",
            "redo",
            "MathType",
            "ChemType",
          ],
        },
        image: {
          // Configuração para permitir o envio de várias imagens
          uploadMultiple: true,
        },
        mathType: {
          licenseKey: "YOUR_LICENSE_KEY",
        },
        chemType: {
          licenseKey: "YOUR_LICENSE_KEY",
        },
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        if (onChange) {
          onChange(data); // Retorna apenas o valor do editor, como você espera
        }
      }}
    />
  );
}

export default MeuEditor;
